import { Heart } from "app/components/Icon";
import React from "react";
import { EmptyState } from "../EmptyState";

export const SavedClassesEmptyState = () => {
  return (
    <EmptyState
      icon={Heart}
      title="your first class"
      description="and it will show up here"
    />
  );
};
